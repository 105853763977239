import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import Heading from 'components/shared/heading';

import styles from './hero.module.scss';
import shape1 from './svg/shape-1.svg';
import shape2 from './svg/shape-2.svg';

const cx = classNames.bind(styles);

const Hero = ({ title }) => (
  <section className={cx('wrapper')}>
    <div className="container">
      <Heading className={cx('title')} tag="h1" color="tertiary">
        {title}
      </Heading>

      <img className={cx('shape-1')} src={shape1} alt="" />
      <img className={cx('shape-2')} src={shape2} alt="" />
    </div>
  </section>
);

Hero.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Hero;
