/* eslint-disable react/prop-types */
import React from 'react';
import parse, { attributesToProps } from 'html-react-parser';
import { graphql } from 'gatsby';

import LayoutMain from 'layouts/layout-main';
import Hero from 'components/pages/static/hero';
import Content from 'components/pages/static/content';
import BannerWithButton from 'components/lazy-blocks/banner-with-button';

const Static = ({ data: { wpPage: data }, pageContext, location }) => {
  // https://github.com/remarkablemark/html-react-parser#htmlparser2
  // The library does parsing on client side differently from server side
  // it results in having a need of passing htmlparser2 to adjust behavior
  // according to the client side behavior
  const reactedContent = parse(pageContext.content, {
    htmlparser2: {
      lowerCaseAttributeNames: true,
    },
    // eslint-disable-next-line consistent-return,react/no-unstable-nested-components
    replace: (domNode) => {
      const props = attributesToProps(domNode.attribs);
      if (domNode.type === 'tag') {
        switch (domNode.name) {
          case 'bannerwithbutton':
            return (
              <BannerWithButton
                title={props.title}
                description={props.description}
                buttonText={props['button-text']}
                buttonUrl={props['button-url']}
                isCompact={props['is-compact']}
                background={props.background}
              />
            );
          default:
            return undefined;
        }
      }
    },
  });
  return (
    <LayoutMain
      seo={{ ...data.seo, date: pageContext.date }}
      headerTheme="secondary"
      footerTheme="with-border"
      withBanner={pageContext.showBanner}
      withBottomOffset
      pageContext={pageContext}
      location={location}
    >
      <Hero title={data.title} />
      <Content content={reactedContent} />
    </LayoutMain>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      ...wpPageSeo
    }
  }
`;

export default Static;
