import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './content.module.scss';

const cx = classNames.bind(styles);

const Content = ({ content }) => (
  <section className={cx('wrapper')}>
    <div className={cx('container', 'content')}>{content}</div>
  </section>
);

Content.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Content;
